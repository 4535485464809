import get from '@/helpers/getLocale';

export default {
  title: get('Doa & Ucapan', 'Prayer & Wishes'),
  desc: get(
    `Silakan kirimkan doa dan ucapan <br />yang tulus untuk kami:`,
    `Please leave your sincere prayer and wishes to us and our families:`,
  ),
  name: get('Nama Anda', 'Your Name'),
  wish: get('Doa & Ucapan', 'Prayer & Wishes'),
  send: get('Kirim', 'Send Wishes'),
  success: get('Sukses!', 'Success!'),
  successMessage: get(`Terima kasih atas do'a dan ucapanya!`, 'Thank you for your wishes!'),
  failedMessage: get(
    `Terjadi kesalahan. Silahkan refresh browser Anda atau coba lagi nanti.`,
    `Something wrong happened. Please try again or reload the browser`,
  ),
  requiredField: get('Wajib diisi.', 'This field is required.'),
};
