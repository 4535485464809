import React from 'react';
import { Box, Center } from '@chakra-ui/react';

import CountDown from '@components/Common/CountDown/V3';
import AddToCalendar from '@components/Common/AddToCalendar';
import WithAnimation from '@components/Common/WithAnimation';
import txtWording from './locales';
import useLang from '@hooks/useLang';

import { BG_COUNTING, VIDEO_COVER } from '@/constants/assets';
import { string } from 'prop-types';
import { ENABLE_GIF_COUNTDOWN } from '@/constants/feature-flags';
import useInvitation from '@hooks/useInvitation';


function CountingDown({ lang }) {
  const defaultLang = useLang();
  const languange = defaultLang;
  const isInvitation = useInvitation();
  
  return (
    <Box zIndex="3" position="relative">
      <Box height="100%">
        {ENABLE_GIF_COUNTDOWN && (
          <video width="100%" autoPlay loop={true} muted={true} playsInline={true}>
            <source src={VIDEO_COVER} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <Box
          width="100%"
          bgImage={`url(${BG_COUNTING})`}
          minHeight="90vh"
          bgSize="cover"
          bgPosition="top"
        >
          <Box paddingTop="42px">
            <CountDown lang={lang} />
            {isInvitation &&<WithAnimation>
              <Center>
                <AddToCalendar
                  text={txtWording.calendar[languange]}
                  size="sm"
                  fontWeight="normal"
                  color="btnMainColor"
                  bgColor="bgSecondary"            
                  className="animation-heart"
                  boxShadow="xl"
                />
              </Center>
            </WithAnimation>}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CountingDown.propTypes = {
  lang: string.isRequired,
};

export default CountingDown;
