import React from 'react';
import { bool, func } from 'prop-types';
import loadable from '@loadable/component';

import txtWording from './locales';
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Text,
} from '@chakra-ui/react';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

import IMG_COVER from '@/images/assets/gallery-3.jpg';
import ImgWave from '@/components/Common/Wave/assets/wave-v4.svg';

import { photos as photos2 } from './api/photos-2';
import { THE_BRIDE, YOUTUBE_PREWEDDING_EMBED } from '@/constants';
import useLang from '@hooks/useLang';

function PhotoContent({ isOpen, onClose }) {
  const defaultLang = useLang();
  const lang = defaultLang;
  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bgColor="bgPrimary" maxW="500px">
        {false && <DrawerCloseButton color="white" />}
        <DrawerBody width="100%" padding="0">
          <Box bgImage={`url(${IMG_COVER})`} height="450px" bgSize="cover" bgPosition="center">
            <Box paddingTop="320px">
              <Text color="mainColorText" textAlign="center">
                OUR STORY JUST BEGIN
              </Text>
              <Text fontSize="4xl" color="mainColorText" textAlign="center" fontFamily="heading">
                {THE_BRIDE}
              </Text>
            </Box>
          </Box>
          <Box
            backgroundImage={`url(${ImgWave})`}
            width="100%"
            height="40px"
            transform="translateY(-38px)"
          />
          <Box height="38px" marginTop="-60px" />
          {/* Images Cover */}
          <Box padding="32px" paddingTop="32px" textAlign="center" color="mainColorText">
            {false && (
              <>
                <Text fontSize="xl">A TEASER ABOUT HAPPINESS</Text>
                <Box marginTop="16px">
                  <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg" boxShadow="xl">
                    <iframe
                      title={`Prewedding of ${THE_BRIDE}`}
                      src={YOUTUBE_PREWEDDING_EMBED}
                      allowFullScreen
                      loading="lazy"
                      style={{ borderRadius: '16px' }}
                    />
                  </AspectRatio>
                </Box>
              </>
            )}

            {/* 2 Section */}
            <Box paddingTop="42px">
              <Text fontSize="xl">WE WERE MET BY GOD</Text>
              <Box marginTop="24px">{isOpen && <LoadableGallerry data={photos2} />}</Box>
            </Box>
          </Box>
          <Center padding="16px 0 80px 0">
            <Button
              size="sm"
              fontWeight="light"
              color="black"
              bgColor="bgSecondary"
              onClick={() => onClose()}
            >
              {txtWording.backContent[lang]}
            </Button>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

PhotoContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;
