import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { bool, string } from 'prop-types';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Text,
  Textarea,
  // hooks
  useToast,
} from '@chakra-ui/react';

import BgBorder from '@/assets/border/wedding.png';
import WithAnimation from '@components/Common/WithAnimation';
import Wishlist from './WishlishCard';

import txtWording from './locales';
import { API_HOSTNAME, API_TABLE_NAME } from '@/constants';
import { BG_PRIMARY, BG_PRIMARY_GRADIENT, DEFAULT_BUTTON_PROPS } from '@/constants/colors';
import { LOADING_STATE, INPUT_COMMON_PROPS, FORM_LABEL_PROPS, ERROR_TYPE } from './types';

const enc = (string) => encodeURIComponent(string);

function WishesSection({ lang }) {
  const [loadingType, setLoadingType] = useState(LOADING_STATE.IDLE);
  const [wishlist, setWishlist] = useState([]);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const [name, setName] = useState('');
  const [ucapan, setUcapan] = useState('');
  const calledOne = useRef(false);
  const toast = useToast();

  const handleSetState = (e, setState) => {
    const value = e.target.value;
    setErrorType(ERROR_TYPE);
    setState(value);
  };

  const finishLoading = () => {
    setLoadingType(LOADING_STATE.IDLE);
  };

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      toast({ status: 'error', title: 'Oops!', description: txtWording.failedMessage[lang] });
    } else {
      toast({
        status: 'success',
        title: messageTitle,
        description: messageContent,
      });
    }
  };

  /**
   * function to get wishlist data
   * @return {void}
   */
  const getData = async () => {
    setLoadingType(LOADING_STATE.GET_DATA);

    try {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'GET',
      };

      const rawResult = await fetch(
        `${API_HOSTNAME}?action=read&tableName=${API_TABLE_NAME}`,
        options,
      );
      const response = await rawResult.json();

      if (response.success) {
        setWishlist(response.data || []);
      } else {
        console.error('ERR_WHEN_GET_DATA', 200);
      }
      finishLoading();
    } catch (e) {
      finishLoading();
      console.error('ERR_WHEN_CALL_DATA', 500);
    }

    calledOne.current = true;
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // validate input data
    if (!name || !ucapan) {
      setErrorType({
        name: !name && txtWording.requiredField[lang],
        ucapan: !ucapan && txtWording.requiredField[lang],
      });
      return;
    }

    setLoadingType(LOADING_STATE.SUBMIT_DATA);

    try {
      const time = dayjs(new Date()).format('DD MMM YYYY | hh:mm:ss A');
      const config = `tableName=${API_TABLE_NAME}&action=insert_wish`;
      const query = `?${config}&nama=${enc(name)}&wish=${enc(ucapan)}&time=${enc(time)}`;

      const rawResult = await fetch(API_HOSTNAME + query, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });

      const response = await rawResult.json();
      if (response.success) {
        setName('');
        setUcapan('');
        getData();
        handleSetAlert(true);
      } else {
        handleSetAlert(false);
      }
    } catch (e) {
      handleSetAlert(false);
    }

    finishLoading();
  };

  /** Side effect to autoscroll */
  useEffect(() => {
    if (!calledOne.current) {
      getData();
    }
  }, []);

  return (
    <Box
      padding="24px 24px"
      bgColor="bgPrimary"
      backgroundImage={`linear-gradient(180deg, ${BG_PRIMARY}, ${BG_PRIMARY_GRADIENT} 400%)`}
    >
      <Box
        padding="42px 24px"
        bgRepeat="no-repeat"
        width="100%"
        bgSize="100% 100%"
        bgImage={`url(${BgBorder})`}
      >
        {/* Title & Description sections */}
        <Box textAlign="center" color="mainColorText">
          <WithAnimation>
            <Heading
              marginTop="32px"
              fontWeight="normal"
              fontSize="32px"
              fontFamily="body"
              dangerouslySetInnerHTML={{ __html: txtWording.title[lang] }}
            />
          </WithAnimation>
          <WithAnimation>
            <Text margin="16px 0" dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }} />
          </WithAnimation>
        </Box>
        {/* Box for FORM */}
        <WithAnimation>
          <Box paddingTop="2">
            <FormControl margin="8px 0" isInvalid={errorType.name}>
              <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                onChange={(e) => handleSetState(e, setName)}
                value={name}
              />
              <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
            </FormControl>
            <FormControl margin="16px 0" isInvalid={errorType.ucapan}>
              <FormLabel {...FORM_LABEL_PROPS}>{txtWording.wish[lang]}:</FormLabel>
              <Textarea
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                onChange={(e) => handleSetState(e, setUcapan)}
                value={ucapan}
              />
              <FormErrorMessage marginTop="4px">{errorType.ucapan}</FormErrorMessage>
            </FormControl>
            <Flex justifyItems="end">
              <Spacer />
              <Box>
                <Button
                  {...DEFAULT_BUTTON_PROPS}
                  isLoading={loadingType === LOADING_STATE.SUBMIT_DATA}
                  size="sm"
                  padding="8px 24px"
                  bgColor="bgSecondary"
                  fontWeight="light"
                  color="btnMainColor"
                  borderRadius="2px"
                  // textTransform="uppercase"
                  onClick={handleSubmit}
                >
                  {txtWording.send[lang]}
                </Button>
              </Box>
            </Flex>
          </Box>
        </WithAnimation>
        {/* Wishlist Card */}
        <WithAnimation>
          <Wishlist wishlistData={wishlist} loading={loadingType === LOADING_STATE.GET_DATA} />
        </WithAnimation>
      </Box>
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);
