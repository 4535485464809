import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';

import BgBorder from '@/assets/border/wedding.png';
// import ImgDivider from '@/assets/border/divider.png';

import WithAnimation from '@components/Common/WithAnimation';
import ViewLocationButton from '@components/Common/Buttons/ViewLocation';
import Image from '@components/Common/LazyImage';

import useShiftTime from '@/hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';

import txtWording from './locales';
import useLang from '@hooks/useLang';

import {
  // WEDDING_AKAD_TIME,
  // WEDDING_AKAD_FULLDATE,
  // WEDDING_AKAD_LOC_NAME,
  // WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
} from '@/constants';
import { IMG_LOGO_WEDDING } from '@/constants/assets';
// import { MUSLIM_INVITATION } from '@/constants/feature-flags';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ ...rest }) {
  const shiftTime = useShiftTime();
  const isInvitation = useInvitation();
  const defaultLang = useLang();
  const lang = defaultLang;

  /** Render Wedding Information */
  const renderWeddingInfo = ({ title, desc, date, time, place, placeRoad, notes }) => {
    return (
      <WithAnimation>
        <Box textAlign="center" color="mainColorText" textTransform="uppercase" fontSize="sm">
          <Text
            fontWeight="bold"
            fontSize="xl"
            margin="24px 0"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Text m="16px 0">{desc}</Text>
          <Text fontSize="md" fontWeight="bold">{time}</Text>
          <Text fontSize="md" fontWeight="bold">{date}</Text>
          {place && <Text fontSize="md" fontWeight="bold" dangerouslySetInnerHTML={{ __html: place }} />}
          {placeRoad && <Text fontSize="xs" dangerouslySetInnerHTML={{ __html: placeRoad }} />}
          {notes && (
            <Text
              marginTop="16px"
              fontSize="xs"
              fontStyle="italic"
              dangerouslySetInnerHTML={{ __html: `*) ${notes}` }}
            />
          )}
        </Box>
      </WithAnimation>
    );
  };

  return (
    <Box bgColor="bgPrimary" padding="32px">
      <Box
        {...rest}
        padding="42px 24px"
        bgRepeat="no-repeat"
        width="100%"
        bgSize="100% 100%"
        bgImage={`url(${BgBorder})`}
      >
        <Box>
          <WithAnimation>
            <Center>
              <Image src={IMG_LOGO_WEDDING} maxWidth="200px" />
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Box marginTop="8px" fontFamily="body" textAlign="center" color="mainColorText">
              <Text fontSize="md">
                {`“Pernikahan mengingatkan kita bahwa hidup kita memiliki makna dan bahwa cinta adalah ikatan yang terkuat, sukacita yang paling bahagia, dan penyembuhan terindah yang pernah kita alami.” `}
              </Text>
              <Text fontSize="md">– Daphne Rose Kingma</Text>
            </Box>
          </WithAnimation>
          {/* Box Reception Information */}
          <WithAnimation>
            <Box textAlign="center" color="mainColorText" textTransform="uppercase" fontSize="sm">
              {/* {false && MUSLIM_INVITATION && <> {renderWeddingInfo ({
                title: txtWording.akadMuslim[lang],
                desc: isInvitation
                  ? txtWording.descAkadInvitationMuslim[lang]
                  : txtWording.descAkadMuslim[lang],
                date: WEDDING_AKAD_FULLDATE,
                time: isInvitation ? WEDDING_AKAD_TIME : '',
                place: WEDDING_AKAD_LOC_NAME,
                placeRoad: isInvitation ? WEDDING_AKAD_LOC_ROAD : '',
              })} </>} */}
              {/* {false && !MUSLIM_INVITATION && <> {renderWeddingInfo ({
                title: txtWording.akadGeneral[lang],
                desc: isInvitation
                  ? txtWording.descAkadInvitationGeneral[lang]
                  : txtWording.descAkadGeneral[lang],
                date: WEDDING_AKAD_FULLDATE,
                time: isInvitation ? WEDDING_AKAD_TIME : '',
                place: WEDDING_AKAD_LOC_NAME,
                placeRoad: isInvitation ? WEDDING_AKAD_LOC_ROAD : '',
              })} </>}
              {isInvitation && (
                <WithAnimation>
                  <Center>
                    <Image src={ImgDivider} maxWidth="100%" marginTop="24px" />
                  </Center>
                </WithAnimation>
              )} */}
              {/* Wedding - info */}
              {!isInvitation &&
                renderWeddingInfo({
                  title: 'PERNIKAHAN',
                  desc: `ACARA PERNIKAHAN DISELENGGARAKAN SECARA TERBATAS PADA:`,
                  date: WEDDING_RESEPSI_FULLDATE,
                })}
              {isInvitation &&
                renderWeddingInfo({
                  title: txtWording.reception[lang],
                  desc: txtWording.descReception[lang],
                  date: WEDDING_RESEPSI_FULLDATE,
                  time: shiftTime,
                  place: WEDDING_RESEPSI_LOC_NAME,
                  placeRoad: WEDDING_RESEPSI_LOC_ROAD,
                  notes: `Sehubungan dengan acara Outdoor & Garden Party, mohon gunakan pakaian yang nyaman dan tidak menggunakan heels yang tinggi.`,
                })}
            </Box>
          </WithAnimation>
          {/* BOX Button View Location */}
          {isInvitation && (
            <WithAnimation>
              <Center>
                <ViewLocationButton
                  marginTop="16px"
                  size="sm"
                  fontWeight="normal"
                  bgColor="bgSecondary"
                  color="btnMainColor"
                  text={txtWording.location[lang]}
                />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(WeddingSection);
